import React from 'react'
import './NewsLetter.css'
import donation_banner from '../Assets/donation_banner.png'

const NewsLetter = () => {
  return (
    <div className='newsletter'>
      <img src={donation_banner} alt="" />
    </div>
  )
}

export default NewsLetter
